import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Seo from "../../components/common/seo"
import Layout from "../../components/common/layout"
import Modal from "react-modal"
import Hero from "../../components/members-zone/hero.js"

import DownloadImg from "../../../static/assets/image/Data-Suite-Download-button-120x115.jpg"
import setupImg1 from "../../../static/assets/image/setupImg1.png"
import setupImg2 from "../../../static/assets/image/setupImg2.png"
import setupImg3 from "../../../static/assets/image/setupImg3.png"
import setupImg4 from "../../../static/assets/image/setupImg4.png"
import setupImg5 from "../../../static/assets/image/setupImg5.png"
import setupImg6 from "../../../static/assets/image/setupImg6.png"
import setupImg7 from "../../../static/assets/image/setupImg7.png"
// import setupImg8 from "../static/assets/image/setupImg8.png"
import updateImg1 from "../../../static/assets/image/setupImg1.png"
import updateImg2 from "../../../static/assets/image/setupImg2.png"
import updateImg3 from "../../../static/assets/image/setupImg3.png"
import updateImg4 from "../../../static/assets/image/setupImg4.png"
import updateImg5 from "../../../static/assets/image/setupImg5.png"

const MembersZone = ({ data }) => {

  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
    
 const [modalIsOpenTwo, setIsOpenTwo] = useState(false)
  function openModalTwo() {
    setIsOpenTwo(true)
  }
  function closeModalTwo() {
    setIsOpenTwo(false)
  }
  
  const [modalIsOpenThree, setIsOpenThree] = useState(false)
  function openModalThree() {
    setIsOpenThree(true)
  }
  function closeModalThree() {
    setIsOpenThree(false)
  }
    
const [modalIsOpenFour, setIsOpenFour] = useState(false)
  function openModalFour() {
    setIsOpenFour(true)
  }
  function closeModalFour() {
    setIsOpenFour(false)
  }

const [modalIsOpenFive, setIsOpenFive] = useState(false)
  function openModalFive() {
    setIsOpenFive(true)
  }
  function closeModalFive() {
    setIsOpenFive(false)
  }
    
const [modalIsOpenSix, setIsOpenSix] = useState(false)
  function openModalSix() {
    setIsOpenSix(true)
  }
  function closeModalSix() {
    setIsOpenSix(false)
  }
    
const [modalIsOpenSeven, setIsOpenSeven] = useState(false)
  function openModalSeven() {
    setIsOpenSeven(true)
  }
  function closeModalSeven() {
    setIsOpenSeven(false)
  }
  
const [modalIsOpenEight, setIsOpenEight] = useState(false)
  function openModalEight() {
    setIsOpenEight(true)
  }
  function closeModalEight() {
    setIsOpenEight(false)
  }    

const [modalIsOpenNine, setIsOpenNine] = useState(false)
  function openModalNine() {
    setIsOpenNine(true)
  }
  function closeModalNine() {
    setIsOpenNine(false)
  }  
  
  const [modalIsOpenTen, setIsOpenTen] = useState(false)
  function openModalTen() {
    setIsOpenTen(true)
  }
  function closeModalTen() {
    setIsOpenTen(false)
  }  
  
  const [modalIsOpenEleven, setIsOpenEleven] = useState(false)
  function openModalEleven() {
    setIsOpenEleven(true)
  }
  function closeModalEleven() {
    setIsOpenEleven(false)
  }  
  
  const [modalIsOpenTwelve, setIsOpenTwelve] = useState(false)
  function openModalTwelve() {
    setIsOpenTwelve(true)
  }
  function closeModalTwelve() {
    setIsOpenTwelve(false)
  }   
  
 const [modalIsOpenThirteen, setIsOpenThirteen] = useState(false)
  function openModalThirteen() {
    setIsOpenThirteen(true)
  }
  function closeModalThirteen() {
    setIsOpenThirteen(false)
  } 
  
const [modalIsOpenFourteen, setIsOpenFourteen] = useState(false)
  function openModalFourteen() {
    setIsOpenFourteen(true)
  }
  function closeModalFourteen() {
    setIsOpenFourteen(false)
} 

const [modalIsOpenFifteen, setIsOpenFifteen] = useState(false)
  function openModalFifteen() {
    setIsOpenFifteen(true)
  }
  function closeModalFifteen() {
    setIsOpenFifteen(false)
    } 
    
const [modalIsOpenSixteen, setIsOpenSixteen] = useState(false)
  function openModalSixteen() {
    setIsOpenSixteen(true)
  }
  function closeModalSixteen() {
    setIsOpenSixteen(false)
} 

const [modalIsOpenSeventeen, setIsOpenSeventeen] = useState(false)
  function openModalSeventeen() {
    setIsOpenSeventeen(true)
  }
  function closeModalSeventeen() {
    setIsOpenSeventeen(false)
} 

    
  Modal.setAppElement("#___gatsby")

      useEffect(() => {
      if (window.location.href.indexOf("fr") > -1) {
        setTimeout(() => {
        document.querySelector("body").classList.add("fr");
      }, 500)
        // alert("hello")
      }
      }, [])
  
    return (
        <div className="membersZone methodologies">
            <Layout>
                <Seo title="Members Zone" />
                <Hero />
                <div className="membersZoneWrapper indMember en-only">
                  <h2>Measurement Methodologies</h2>
                  <p>All COMMB circulation measurement methodologies are designed to identify the number of people that come within viewing distance of an out-of-home advertising (OOH) face with a reliable opportunity to see that face.</p>
                  <br />
                  <p>Being in the general area of an outdoor face, or simply inside a particular venue that houses OOH advertising, is not enough. COMMB requires ‘proof’ that there is a reliable opportunity to see, and uses both precision and the highest standards of quantitative measurement in sourcing that proof.</p>
                  <hr />
                  <br />
                  <h3>Outdoor Advertising</h3>
                  <p>The steps to determining average daily circulation.</p>
                  <ul>
                      <li>Step 1. Source vehicular traffic volume</li>
                      <li>Step 2. Adjust traffic volume to reflect COMMB-approved visibility criteria</li>
                      <li>Step 3. Apply load factor (average number of passengers per vehicle)</li>
                      <li>Step 4. Adjust for hours of illumination</li>
                      <li>Step 5. Conduct pedestrian studies using COMMB-approved visibility criteria (distance and direction)</li>
                      <li>Step 6. Calculate average daily circulation per face</li>
                  </ul>
                  <p><a href="https://commb.wpengine.com/wp-content/uploads/2018/02/Outdoor-Advertising-methodology.pdf">More details here</a></p>
                  <br />
                  <h3>Place-Based Advertising</h3>
                  <p>The steps to determining average weekly impressions.</p>
                  <ul>
                      <li>Step 1. Conduct pedestrian count studies</li>
                      <li>Step 2. Convert pedestrian counts to number of weekly impressions per face</li>
                      <li>Step 3. Calculate average weekly impressions per face for each place-based network in each market</li>
                  </ul>                 
                  <p><a href="https://commb.wpengine.com/wp-content/uploads/2018/02/Indoor-Advertising-Methodology.pdf">More details here</a></p>
                  
                  <br />
                  <h3>Audience Numbers</h3>
                  <p>Do outdoor or place-based audience numbers provided by an OOH Company reflect Industry Standards?</p>
                  <ul>
                      <li>Outdoor Circulation Checklist</li>
                      <li>Place-Based Circulation Checklist</li>
                  </ul>                  
                </div>
                <div className="membersZoneWrapper indMember fr-only">
                  <h2>Principes d’Évaluation</h2>
                  <p>Pour représenter la norme d’évaluation de l’affichage au Canada, sous la surveillance de sa comité de recherche, COMMB s’appuie sur les principes directeurs ci-dessous:</p>
                  <br />
                  <ul>
                    <li onClick={openModal}>
                      <a> Des Possibilités sûres de voir</a>
                    </li>
                     <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                      <h2>Des possibilités sûres de voir</h2>
                      <p>Comment savoir si les évaluations de COMMB sont exactes?</p>
                      <p>
                        L’évaluation de la circulation pédestre et routière est fondée sur la possibilité sûre de voir une face publicitaire précise. COMMB s’appuie sur des normes rigoureuses pour veiller à ce que la circulation soit évaluée de manière exacte et ne soit pas surévaluée.
                      </p>
                      <p>
                        Les exemples suivants montrent comment on applique la norme d’évaluation de COMMB et font état de la différence entre les évaluations validées par COMMB et les évaluations non validées.
                      </p>
                      <h4>
                        La norme d’évaluation de COMMB pour Publicité extérieure	 	
                      </h4>                      
                      <p>
                        Les comptes de circulation extérieure de COMMB comprennent seulement les véhicules qui se dirigent vers la face publicitaire. Les comptes de circulation à deux sens sont réduits de 50 %.	 	
                      </p>
                      <p>
                        Les comptes de circulation de COMMB pour les produits extérieurs doivent respecter les distances de visibilité approuvées par l’industrie. Les normes propres à chacun des produits sont adoptées et tiennent compte de la visibilité pour les véhicules et les piétons
                      </p>
                      <p>
                        COMMB réduit le compte de circulation pour les faces publicitaires qui ne sont pas illuminées pendant 24 heures.
                      </p>
                      <h4>
                        Sans la norme d’évaluation de COMMB      
                      </h4>
                      <p>
                        On utilise les comptes de circulation routière à deux sens.                        
                        Aucune organisation de l’industrie n’établit les comptes admissibles de circulation.
                        La moyenne des comptes de circulation quotidienne est appliquée à toutes les faces publicitaires sans distinction.        
                      </p>
                      <hr />
                      <h4>
                        La norme d’évaluation de COMMB pour Médias intérieurs
                      </h4>
                      <p>
                        Sur les lieux de vente, le compte de la circulation pour les affiches situées dans les cabines de toilettes tient compte de la norme de COMMB qui établit qu’une seule affiche de cabine peut être vue par visite aux toilettes.  
                      </p>
                      <p>
                        COMMB définit une zone de visibilité pour chaque affiche autostable ou murale située dans des lieux spécifiques. Présentemment, ceci s’applique aux affiches dans les campus universitaires. On compte seulement les visiteurs qui s’approchent de la face publicitaire à l’intérieur de la distance établie.  
                      </p>
                      <h4>
                        	Sans la norme d’évaluation de COMMB
                      </h4>
                      <p>
                        On considère la circulation totale du lieu intérieur de vente comme la circulation totale des affiches dans les cabines des toilettes. On utilise le compte de la circulation totale du lieu comme compte de circulation.
                      </p>
                      <a className="closeBtnModal" onClick={closeModal}>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.688"
                          height="17.688"
                          viewBox="0 0 17.688 17.688"
                        >
                          <path
                            id="close-icon"
                            d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                            transform="translate(-7.5 -7.5)"
                            fill="#fff"
                          ></path>
                        </svg>
                      </a>   
                    </Modal>
                    <li onClick={openModalTwo}>
                      <a> Des principes statistiques fiables </a>
                    </li>
                    <Modal
                        isOpen={modalIsOpenTwo}
                        onRequestClose={closeModalTwo}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                        <h2>
                          Des principes statistiques fiables
                        </h2>
                        <p>
                          COMMB a recours aux normes de recherche statistique les plus élevées dans ses méthodes d’évaluation, car la plus infime des déviations par rapport aux normes peut entraîner des résultats non fiables. Ces normes statistiques comprennent les facteurs d’extrapolation, les méthodes de calcul de la moyenne et l’échantillonnage.
                        </p>
                        <hr />
                        <h3>
                          Facteurs d’extrapolation                        
                        </h3>
                        <h4>
                          À l’extérieur                        
                        </h4>
                        <p>
                          On utilise des facteurs fondés sur des statistiques sûres pour ajuster les comptes bruts de circulation routière et pédestre afin qu’ils représentent une journée moyenne de l’année. Ces facteurs tiennent compte des fluctuations de circulation selon les jours de la semaine, le moment de la journée, les mois et les saisons et les zones d’utilisation des terres, qu’elles soient résidentielles, industrielles ou commerciales. Les facteurs et leur mise en pratique sont établis grâce aux études approfondies sur la circulation réalisées par les municipalités, les administrations routières et les firmes de contrôle de la circulation routière.
                        </p>                      
                        <h4>À l’intérieur</h4>
                        <p>
                          Dans les lieux de vente comme les restaurants, les bars, les centres commerciaux, les centres de culture physique et les campus universitaires et collégiaux, les facteurs d’extrapolation sont définis par une analyse de régression des comptes de circulation d’une semaine complète. Ceux-ci tiennent compte des variations de la circulation pédestre selon le moment de la journée, les jours de la semaine, les mois et les saisons, le type de région urbaine et les heures d’ouverture. On les utilise pour établir un compte moyen de la circulation à partir des comptes de circulation sur le terrain.
                        </p>
                        <hr />
                        <h3>
                          Méthodes de calcul de la moyenne
                        </h3>
                        <p>
                          Le choix d’une méthode de calcul de la moyenne pour évaluer la circulation moyenne quotidienne ou la circulation moyenne hebdomadaire repose sur la nature et la complexité des différents réseaux d’affichage. Le nombre de faces publicitaires situées sur les lieux de vente intérieurs peut être considérablement différent d’un établissement à l’autre, c’est pourquoi il faut avoir recours au calcul de la moyenne pondérée dans le calcul de la circulation hebdomadaire moyenne par face pour un marché précis. Le calcul de la moyenne pondérée tient compte de la proportion des faces de chaque établissement plutôt que de traiter tous les établissements sans distinction.
                        </p>
                        <p>
                          Le comité de recherche de COMMB utilise son expertise pour cerner la méthode appropriée de calcul de la moyenne.
                        </p>
                        <hr />
                        <h3>
                          Échantillonnage
                        </h3>
                        <p>
                          COMMB procède à de nombreuses études pour veiller à la fiabilité de ses comptes de circulation, des facteurs qu’il utilise dans ses formules et de ses normes de vérification. Il a recours à un échantillon de toutes ses études pour obtenir le niveau de fiabilité le plus élevé, maintenant ainsi un niveau de confiance à 95 % et une marge d’erreur de moins de 10 %.
                        </p>
                        <a className="closeBtnModal" onClick={closeModalTwo}>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.688"
                          height="17.688"
                          viewBox="0 0 17.688 17.688"
                        >
                          <path
                            id="close-icon"
                            d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                            transform="translate(-7.5 -7.5)"
                            fill="#fff"
                          ></path>
                        </svg>
                      </a>            
                    </Modal>
                    <li onClick={openModalThree}>
                      <a> Des données propres aux marchés </a>
                    </li>
                    <Modal
                        isOpen={modalIsOpenThree}
                        onRequestClose={closeModalThree}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                      <h2>
                        Des données propres aux marchés
                      </h2>
                      <p>
                        Afin de tenir compte de la variance notable d’un marché à l’autre en ce qui a trait aux tendances en fait de déplacement des consommateurs, à la taille et à la configuration des routes et aux tendances commerciales, COMMB réalise des études d’évaluation pour plus de 275 marchés. COMMB s’assure ainsi d’offrir aux annonceurs et à leurs agences les comptes les plus précis possible.
                      </p>
                      <ul>
                        <li>
                          Les études sur la circulation extérieure se font dans plus de 275 marchés canadiens, tant dans les grandes villes comme Toronto, Vancouver et Montréal que dans les régions rurales comme Témiscaming, Notre-Dame-du-Lac et Wood Buffalo.
                        </li>
                        <li>
                          Les études de circulation des réseaux de lieux de vente se font dans 45 marchés dans toutes les provinces.
                        </li>
                        <li>
                          Les études de facteur d’occupation pour évaluer le nombre moyen de passagers se déplaçant par véhicule sont réalisées dans chaque marché – régions métropolitaines de recensement (RMR) et agglomérations de recensement (AR) – du COMMB.                          
                        </li>
                        <li>
                          Les études d’intramarché pour évaluer le pourcentage de véhicules qui proviennent de l’intérieur du marché – une exigence importante dans le calcul exact des points d’exposition bruts (PEB) – sont réalisées dans plus de 45 marchés (RMR et AR) du COMMB.                          
                        </li>
                      </ul>
                      <p>
                        Peu importe le marché ciblé, l’ampleur des études de COMMB sur la circulation fournit les données les plus fiables qui soient.                        
                      </p>
                      <a className="closeBtnModal" onClick={closeModalThree}>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.688"
                          height="17.688"
                          viewBox="0 0 17.688 17.688"
                        >
                          <path
                            id="close-icon"
                            d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                            transform="translate(-7.5 -7.5)"
                            fill="#fff"
                          ></path>
                        </svg>
                      </a>
                    </Modal>
                    <li onClick={openModalFour}>
                      <a> Les évaluations sur le terrain </a>
                    </li>
                    <Modal
                        isOpen={modalIsOpenFour}
                        onRequestClose={closeModalFour}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                      <h2>
                        Les évaluations sur le terrain
                      </h2>
                      <p>
                        COMMB utilise des méthodes fiables et éprouvées pour prélever des données sur la circulation sur les routes et dans les lieux de vente où se trouvent des affiches publicitaires.                        
                      </p>
                      <p>
                        COMMB n’obtient pas de données de la part d’autres intervenants, comme les établissements intérieurs, dont les méthodes d’évaluation peuvent être inconstantes et difficiles à vérifier.                      
                      </p>                
                      <p>
                        Les données provenant du terrain sont recueillies par les administrations routières, les firmes de contrôle de la circulation routière et les vérificateurs de COMMB.
                      </p>
                      <a className="closeBtnModal" onClick={closeModalFour}>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.688"
                          height="17.688"
                          viewBox="0 0 17.688 17.688"
                        >
                          <path
                            id="close-icon"
                            d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                            transform="translate(-7.5 -7.5)"
                            fill="#fff"
                          ></path>
                        </svg>
                      </a>
                    </Modal>
                    
                  </ul>  
                  <hr />
                  <h3>Audience Numbers</h3>
                  <p>Do outdoor or place-based audience numbers provided by an OOH Company reflect Industry Standards?</p>
                  <br />
                  <ul>
                      <li><a href="/Liste-de-vérification-des-circulations_extérieur.pdf">Outdoor Circulation Checklist</a></li>
                      <li><a href="/Liste-de-vérification-des-circulations_intérieur.pdf">Place-Based Circulation Checklist</a></li>
                  </ul>                  
                </div>                
            </Layout>
        </div>
    )
}

export default MembersZone